e4.forms = {
    init: function () {
        if ( jQuery( '.js-e-form-dw, form[data-validate="true"]' ).length > 0 ) {
            e4.require.js( e4.settings.require.jQueryValidate, function () {
                e4.forms.validate.init();
                e4.forms.mailchimp.init();
            } );
        }
        e4.forms.toggleState();
        e4.forms.setSubmitPage();
    },
    setSubmitPage: function () {
        jQuery( '.js-e-form-dw' ).find( 'input[name="SubmitPage"]' ).val( location.href );
    },
    toggleState: function () {
        // Toggle class on form groups to focus active fields and labels.
        jQuery( '.js-e-form-dw' ).find( '.form-group' ).find( ':input' ).on( 'focus blur', function ( event ) {
            var jThisFormGroup = jQuery( this ).closest( '.form-group' );
            if ( event.type === 'focus' ) {
                jThisFormGroup.addClass( 'has-focus' );
            }
            else {
                jThisFormGroup.removeClass( 'has-focus' );
            }
        } );
    },
    validate: {
        init: function () {
            if ( jQuery( '.js-e-form-dw, form[data-validate="true"]' ).length > 0 ) {
                // The following two functions has been moved to require callback.
                // e4.forms.validate.localization.load();
                // e4.forms.validate.setDefaults();
                e4.forms.validate.bindEvents();
            }
        },
        bindEvents: function () {
            // Bind validation method to all Dynamiweb forms and custom forms with data-validate="true" using default settings.
            jQuery( '.js-e-form-dw, form[data-validate="true"]' ).each( function () {
                jQuery( this ).validate();
            } );
        }
    },
    dw: {
        submitHandler: function ( domForm ) {
            if ( jQuery( domForm ).is( '.js-e-form-dw' ) === true ) {
                // Read Dynamicweb spam script from inline script block inside the forms.
                var fnDwFormSpamScript = e4.data.get( 'forms.dwSpamScripts.' + domForm.id );
                if ( jQuery.isFunction( fnDwFormSpamScript ) === true ) {
                    fnDwFormSpamScript.call( window, domForm );
                }
            }
            domForm.submit();
        }
    },
    mailchimp: {
        init: function () {
            if ( jQuery( '.js-e-form-dw-mailchimp-signup' ).length > 0 ) {
                e4.forms.mailchimp.setSubmitHandler();
            }
        },
        setSubmitHandler: function () {
            jQuery( '.js-e-form-dw-mailchimp-signup' ).each( function ( index, element ) {
                jQuery( element ).data( 'validator' ).settings.submitHandler = e4.forms.mailchimp.submitHandler;
            } );
        },
        submitHandler: function ( domForm ) {
            var jForm = jQuery( domForm );
            jQuery.ajax( {
                type: jForm.attr( 'method' ),
                url: jForm.find( 'input[name="MailChimp_SignUpURL"]' ).val().replace( '/post?', '/post-json?' ),
                data: jForm.serialize(),
                timeout: 5000,
                cache: false,
                dataType: 'jsonp',
                jsonp: 'c',
                context: domForm
            } ).always( function ( data ) {
                e4.forms.mailchimp.responseHandler( this, data );
            } );
        },
        responseHandler: function ( domForm, data ) {
            var jForm = jQuery( domForm ),
                jMailChimpSignUp = jForm.closest( '.js-e-mailchimp-signup' );
            jMailChimpSignUp.find( '.js-e-msg' ).removeClass( 'show' );
            if ( data.result === 'success' ) {
                var jMsgSuccess = jMailChimpSignUp.find( '.js-e-msg-success' );
                if ( jMsgSuccess.html() === '' ) {
                    jMsgSuccess.html( data.msg );
                }
                jMailChimpSignUp.css( {
                    'min-height': ( function () {
                        return jMailChimpSignUp.height();
                    } )()
                } );
                jForm.fadeOut( e4.settings.animation.speed, function () {
                    jMsgSuccess.addClass( 'show' );
                } );
            }
            else if ( data.result === 'error' && data.msg.split( ' ' )[ 0 ] === jForm.find( 'input[name="EMAIL"]' ).val() ) {
                var jMsgMailExists = jMailChimpSignUp.find( '.js-e-msg-mail-exists' );
                if ( jMsgMailExists.length === 1 ) {
                    if ( jMsgMailExists.html() === '' ) {
                        jMsgMailExists.html( data.msg );
                    }
                    else {
                        jMsgMailExists.html( jMsgMailExists.html().replace( '{{Email}}', jForm.find( 'input[name="EMAIL"]' ).val() ) );
                    }
                    jMsgMailExists.addClass( 'show' );
                }
            }
            else {
                var jMsgError = jMailChimpSignUp.find( '.js-e-msg-error' );
                if ( jMsgError.length === 1 ) {
                    if ( jMsgError.html() === '' ) {
                        jMsgError.html( data.msg );
                    }
                    jMsgError.addClass( 'show' );
                }
            }
        }
    }
};
/*
 jQuery.validator.addMethod( 'localizedNumber', function ( value, domElm ) {
 var regExpLocalizedNumber = new RegExp( '^-?(?:\\d+|\\d{1,3}(?:\\' + vetoryl.logbook.number.intergerSeperator + '\\d{3})+)?(?:\\' + vetoryl.logbook.number.decimalSeperator + '\\d+)?$' );
 return this.optional( domElm ) || regExpLocalizedNumber.test( value );
 }, jQuery.validator.messages.number );
 */

