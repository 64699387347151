e4.util = {
    init: function () {
        e4.util.catchInternalBackLinks();
        e4.util.catchBlockLinks();
    },
    isInViewport: function ( jElement ) {
        var domElm = jElement.get( 0 ),
            objBounds = domElm.getBoundingClientRect();
        return objBounds.top < window.innerHeight && objBounds.bottom > 0;
    },
    formatStringToJavascriptNumber: function ( strNumber ) {
        return parseFloat( String( strNumber ).replace( new RegExp( '\\' + e4.settings.separatorInteger, 'g' ), '' ).replace( new RegExp( '\\' + e4.settings.separatorDecimal, 'g' ), '.' ) );
    },
    formatJavascriptNumberToLocaleString: function ( intNumber ) {
        var strNumber = String( parseFloat( Math.round( intNumber * 100 ) / 100 ).toFixed( 2 ) ).replace( /[^\d\.\-]/g, '' ).replace( /(\.\d{2})[\W\w]+/g, '$1' ).split( '' ).reverse().join( '' ).replace( /(\d{3})/g, '$1.' ).split( '' ).reverse().join( '' ).replace( /^([\-]{0,1})\./, '$1' ).replace( /\.(\d\d)$/, ',' + '$1' ).replace( /\.(\d)$/, ',' + '$1' + '0' ).replace( /\.$/, ',00' );
        if ( e4.settings.lang !== 'da' ) {
            strNumber = strNumber.replace( /\./g, '{integer}' );
            strNumber = strNumber.replace( /\,/g, '{decimal}' );
            strNumber = strNumber.replace( /{integer}/g, e4.settings.separatorInteger );
            strNumber = strNumber.replace( /{decimal}/g, e4.settings.separatorDecimal );
        }
        return strNumber;
    },
    debug: function ( objData, boolShow, strConsoleType ) {
        if ( boolShow === true || e4.settings.debug.show === true ) {
            var _strConsoleType = ( strConsoleType !== undefined && strConsoleType in console === true ) ? strConsoleType : e4.settings.debug.consoleType;
            if ( jQuery.isArray( objData ) === true ) {
                console[ _strConsoleType ].apply( null, objData );
            }
            else {
                console[ _strConsoleType ].call( null, objData );
            }
        }
    },
    registerEvents: function ( strEvents, strNamespaceLocal ) {
        var objEvents = {},
            strNamespace = [
                e4.settings.event.namespace,
                strNamespaceLocal
            ].join( '.' );
        jQuery.each( strEvents.split( ' ' ), function ( index, element ) {
            objEvents[ element ] = [
                element,
                strNamespace
            ].join( '.' );
        } );
        return objEvents;
    },
    debounce: function ( fnFunction, intDelay ) {
        var intDeferTimer = null;
        return function () {
            var thisArg = this,
                arrArgs = arguments;
            clearTimeout( intDeferTimer );
            intDeferTimer = setTimeout( function () {
                fnFunction.apply( thisArg, arrArgs );
            }, intDelay );
        };
    },
    catchInternalBackLinks: function () {
        jQuery( document ).on( 'click', '.js-e-back-link', function ( event ) {
            if ( document.referrer.indexOf( location.protocol + '//' + location.host ) === 0 ) {
                event.preventDefault();
                history.back();
            }
        } );
    },
    catchBlockLinks: function () {
        jQuery( document ).on( 'click', '.js-e-block-link', function ( event ) {
            var jBlockLink = jQuery( event.currentTarget ).find( 'a[href]' ).first();
            if ( jBlockLink.length === 1 && jQuery( event.target ).closest( 'a[href], form' ).length === 0 ) {
                if ( jBlockLink.attr( 'target' ) === '_blank' ) {
                    window.open( jBlockLink.attr( 'href' ) );
                }
                else {
                    location.href = jBlockLink.attr( 'href' );
                }
            }
        } );
    },
    getBreakPoint: function () {
        var intWindowWidth = Math.max( jQuery( window ).width(), window.innerWidth );
        if ( intWindowWidth >= e4.settings.breakpoint.xs && intWindowWidth < e4.settings.breakpoint.sm ) {
            return 'xs';
        }
        else if ( intWindowWidth >= e4.settings.breakpoint.sm && intWindowWidth < e4.settings.breakpoint.md ) {
            return 'sm';
        }
        else if ( intWindowWidth >= e4.settings.breakpoint.md && intWindowWidth < e4.settings.breakpoint.lg ) {
            return 'md';
        }
        else if ( intWindowWidth >= e4.settings.breakpoint.lg && intWindowWidth < e4.settings.breakpoint.xl ) {
            return 'lg';
        }
        else if ( intWindowWidth >= e4.settings.breakpoint.xl ) {
            return 'xl';
        }
        return null;
    },
    hash: {
        get: function ( strKey ) {
            var objReturnValue,
                arrHashes = [];
            if ( location.hash.indexOf( '#/' ) === 0 && location.hash.lastIndexOf( '/' ) === location.hash.length - 1 ) {
                arrHashes = location.hash.substring( 2, location.hash.length - 1 ).split( '&' );
            }
            if ( strKey === undefined ) {
                objReturnValue = {};
            }
            else {
                strKey = e4.util.hash.decode.key( e4.util.hash.encode.key( strKey ) );
            }
            for ( var i = 0; i < arrHashes.length; i++ ) {
                try {
                    var arrHashKeyValue = arrHashes[ i ].split( '=' ),
                        hashKey = e4.util.hash.decode.key( arrHashKeyValue[ 0 ] ),
                        hashValue = e4.util.hash.decode.value( arrHashKeyValue.slice( 1 ).join( '=' ) );
                    if ( strKey === hashKey ) {
                        objReturnValue = hashValue;
                        break;
                    }
                    else if ( strKey === undefined ) {
                        objReturnValue[ hashKey ] = hashValue;
                    }
                }
                catch ( e ) {
                }
            }
            return objReturnValue;
        },
        set: function ( strKey, objValue ) {
            var arrHashes = e4.util.hash.buildNewHashArray( strKey, objValue ),
                strHash = e4.util.hash.buildNewHashString( arrHashes );
            //console.log( location.hash, e4.util.hash.getCurrentHashStringValue(), strHash, arrHashes );
            if ( e4.util.hash.getCurrentHashStringValue() !== strHash ) {
                location.hash = strHash;
                //console.log( 'hash changed - set' );
            }
        },
        replace: function ( strKey, objValue ) {
            var arrHashes = e4.util.hash.buildNewHashArray( strKey, objValue ),
                strHashNew = e4.util.hash.buildNewHashString( arrHashes ),
                strHashCurrent = e4.util.hash.getCurrentHashStringValue(),
                strUrl = location.href;
            //console.log( location.hash, e4.util.hash.getCurrentHashStringValue(), strHashNew, arrHashes );
            if ( strHashCurrent !== strHashNew ) {
                if ( location.href.indexOf( '#' ) === -1 ) {
                    strUrl = strUrl + '#' + strHashNew;
                }
                else {
                    strUrl = strUrl.replace( '#' + strHashCurrent, '#' + strHashNew );
                }
                window.history.replaceState( null, '', strUrl );
                jQuery( window ).triggerHandler( 'hashchange' );
                //console.log( 'hash changed - replace' );
            }
        },
        remove: function ( strKey ) {
            e4.util.hash.set( strKey, null );
        },
        buildNewHashArray: function ( strKey, objValue ) {
            var arrHashes = [],
                boolKeyExists = false;
            strKey = e4.util.hash.encode.key( strKey );
            jQuery.each( e4.util.hash.get(), function ( hashKey, hashValue ) {
                hashKey = e4.util.hash.encode.key( hashKey );
                hashValue = e4.util.hash.encode.value( hashValue );
                if ( hashKey === strKey ) {
                    boolKeyExists = true;
                    if ( objValue !== null ) {
                        hashValue = e4.util.hash.encode.value( objValue );
                        arrHashes.push( hashKey + '=' + hashValue );
                    }
                }
                else {
                    arrHashes.push( hashKey + '=' + hashValue );
                }
            } );
            if ( boolKeyExists === false && objValue !== null ) {
                arrHashes.push( strKey + '=' + e4.util.hash.encode.value( objValue ) );
            }
            return arrHashes;
        },
        buildNewHashString: function ( arrHashes ) {
            var strHash = '';
            if ( arrHashes.length !== 0 ) {
                strHash = '/' + arrHashes.join( '&' ) + '/';
            }
            return strHash;
        },
        getCurrentHashStringValue: function () {
            return location.hash.replace( /^#/, '' );
        },
        encode: {
            key: function ( strKey ) {
                return encodeURIComponent( String( strKey ).replace( /\s/g, '_' ) );
            },
            value: function ( objValue ) {
                if ( String( objValue ).charAt( 0 ) === '{' || typeof objValue === 'object' ) {
                    try {
                        objValue = JSON.stringify( objValue );
                    }
                    catch ( e ) {
                    }
                }
                return objValue === undefined ? '' : encodeURIComponent( objValue );
            }
        },
        decode: {
            key: function ( strKey ) {
                return decodeURIComponent( strKey );
            },
            value: function ( objValue ) {
                objValue = decodeURIComponent( objValue );
                if ( objValue.charAt( 0 ) === '{' ) {
                    try {
                        objValue = JSON.parse( objValue );
                    }
                    catch ( e ) {
                    }
                }
                return objValue;
            }
        }
    }
};
